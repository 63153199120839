body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  display: flex;
  gap: 1rem;
}

.loader .dot {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #4A90E2;
  animation: bounce 2s infinite ease-in-out;
}

.loader .dot:nth-child(2) {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2rem);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;